import { useEffect, useState } from "react";
import Button from "./components/Button";
import Grid from "./components/Grid";
import Navbar from "./components/Navbar";
import axios from "axios";
import { AnimatePresence, motion } from "framer-motion";

function App() {
  const [photos, setPhotos] = useState([]);
  const [updateUI, setUpdateUI] = useState("");

  useEffect(() => {
    axios
      .get("https://gallery-app-tajq.onrender.com/api/get")
      .then((res) => {
        console.log(res.data);
        setPhotos(res.data);
      })
      .catch((err) => console.log(err));
  }, [updateUI]);

  return (
    <AnimatePresence>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ease:"easeOut",duration:1}}
        className="App"
      >
        <Navbar />
        <Grid photos={photos} />
        <Button setUpdateUI={setUpdateUI} />
      </motion.div>
    </AnimatePresence>
  );
}

export default App;
